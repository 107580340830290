import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import Typography from '@material-ui/core/Typography';
import PageTitle from '@/components/PageTitle';
import Plans from '@/components/Pricing/Plans';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageTitle mdxType="PageTitle">
      <Typography variant="h1" style={{
        flexBasis: '100%'
      }} mdxType="Typography">
  Pricing to suit your business
      </Typography>
      <Typography variant="h5" style={{
        flexBasis: '100%'
      }} mdxType="Typography">
  No setup cost. Cancel at any time.
      </Typography>
      <Typography variant="body1" textAlign="center" mdxType="Typography">
  All packages have no lock-in contract.
      </Typography>
    </PageTitle>
    <Plans mdxType="Plans" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      